/* cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v7/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v7/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body
/* TODO: theme switching */
/* [data-theme='dark'] */ {
  --bg-color: #29303c;
  --color: #e1ddf0;
  --shadow-color: rgb(0, 0, 0);
  --gray: rgb(29, 29, 29);

  --transparent-light-1: rgba(255, 255, 255, 0.2);
  --transparent-light-2: rgba(255, 255, 255, 0.1);
  --transparent-light-3: rgba(255, 255, 255, 0.05);

  --transparent-dark-1: rgba(0, 0, 0, 0.5);
  --transparent-dark-2: rgba(0, 0, 0, 0.2);
  --transparent-dark-3: rgba(0, 0, 0, 0.1);
  --transparent-dark-4: rgba(0, 0, 0, 0.05);

  --brand-color: #5f48b0;
  --border-color: #333c4b;
  --card-color: #343a46;
  --color-warning: #ff9c0833;
  --color-error: #ff080833;
  --color-inactive: #ccc;
  --color-notice: #bfcaffbb;
  --color-notice-bg: #bfcaff3d;
  --link-color: rgb(26, 141, 236);
}

body[data-theme="light"] {
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--color);
  font-size: 1.2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

label,
input,
button {
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  color: var(--color);
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.warning {
  background-color: var(--color-warning);
}

.error {
  background-color: var(--color-error);
}

.notice {
  background-color: var(--color-notice-bg);
}

.transparentButton {
  font-weight: bold;
  border: none;
  background-color: transparent;
  color: inherit;
}

.transparentButton.inactive {
  pointer-events: none;
  color: var(--color-inactive);
}

.primaryBtn,
.secondaryBtn {
  font-weight: bold;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: inherit;
  padding: 0.8rem 2rem;
  background-color: var(--brand-color);
  border: 0.125rem solid var(--transparent-light-1);
  border-radius: 0.8rem;
  transition: all 0.2s ease-in-out;
}

.secondaryBtn {
  background-color: var(--transparent-light-2);
}

.primaryBtn:hover,
.primaryBtn:focus,
.secondaryBtn:hover,
.secondaryBtn:focus,
.transparentButton:hover,
.transparentButton:focus {
  opacity: 0.5;
}

.secondaryBtn:disabled,
.primaryBtn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.primaryInput {
  padding: 0.5rem;
  border-radius: 0.4rem;
  border: 0.125rem solid var(--transparent-light-1);
  background-color: var(--transparent-light-3);
  transition: all 0.1s ease-in-out;
}

.primaryInput:hover,
.primaryInput:focus {
  border-color: 0.125rem solid var(--color);
  outline: none;
}

.pending {
  cursor: wait;
}

.pending::after {
  display: inline-block;
  animation: ellipsis 1.25s infinite;
  content: ".";
  width: 1em;
  text-align: left;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.full-width {
  width: 100%;
}

.link {
  text-decoration: none;
  color: var(--link-color);
}

.pb-1 {
  padding-bottom: 1rem;
}

.defaultLink{
  color: var(--link-color);
  text-decoration: none;
}

@keyframes ellipsis {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
}
