footer {
  margin-top: auto;
  padding: 2rem 0 1rem;
}

.footerItems {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  list-style-type: none;
}
.footerItem {
  position: relative;
  padding: 0 10px;
}
.footerLink {
  text-decoration: none;
  color: var(--link-color);
}
.footerLink:hover {
  color: var(--color-notice);
}
.footerItem::after{
  content: "";
  position: absolute;
  right: 0px;
  bottom: 0;
  height: 20px;
  width: 2px;
  background-color: #fff;
}
.footerItem:last-child::after{
  content: none;
}

.footerRiskNotice {
  max-width: 44rem;
  margin: 0 auto;
  padding: 0 1rem;
  font-size: 0.84rem;
  color: var(--color-notice);
}

.linksList {
  margin: 1rem auto 0;
  padding: 0.7rem 0 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.linkItem:not(:last-child) {
  margin-right: 1rem;
}

.linkItem a {
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  text-decoration: none;
  color: inherit;
  transition: 0.1s;
}

.linkItem a:hover {
  opacity: 0.5;
}

.icon {
  /* show a link title on icon hover */
  position: relative;
  z-index: -1;
}

@media (max-width: 500px){
  .footerItems{
    font-size: 0.8rem;
  }
}