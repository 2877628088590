.auth {
  display: flex;
  justify-content: center;
  margin: 1rem auto;
  width: 90%;
}

.auth:empty {
  margin: 2vh 0 0 0;
}

.checkAddressWrapper {
  max-width: 460px;
  margin: 0 1rem;
}

.checkAddressTitle {
  margin-bottom: 42px;
  font-size: 26px;
  letter-spacing: 1px;
  text-align: center;
}

.subscriptionForm {
  max-width: 38rem;
  letter-spacing: 1px;
}

.sf-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.sf-rowColumn {
  flex-direction: column;
}

.sf-rowColumn label {
  margin-bottom: 0.2rem;
}

.sf-row input[type="checkbox"] {
  margin-right: 0.5rem;
  margin-top: 0.4rem;
  min-width: 5%;
}

.sf-row .primaryInput {
  padding: 12px 16px;
  width: 90%;
  margin: 0 auto 20px;
}

.sf-row .primaryBtn {
  width: 98%;
  margin: 16px auto 0;
}

.sf-row .trustLabel {
  margin: 28px auto 0;
  font-weight: 400;
  text-align: center;
  color: var(--color-notice);
}