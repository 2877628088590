.userProducts {
  max-width: 60rem;
  margin: 0 auto;
  width: 90%;
}

.howToUseWrapper {
  margin: 1%;
  padding: 2%;
  letter-spacing: 0.02rem;
  line-height: 1.5rem;
  border-radius: 1.2rem;
  border: 1px solid var(--border-color);
  background-color: var(--transparent-dark-3);
}

.howToUseTitle {
  margin: 0;
}

.howToUseDescription {
  margin: 0;
  padding: 1.4rem 0 0;
}

.productsList {
  display: flex;
  flex-wrap: wrap;
}

.textInfo {
  margin: 0 auto;
  font-size: 1.6rem;
  text-align: center;
}

.userProduct {
  max-width: 26rem;
  min-width: 13rem;
  width: 26.4%;
  margin: 1%;
  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.5rem;
  border-radius: 1.2rem;
  background-color: var(--card-color);
}

.userProduct .top {
  margin-bottom: 2rem;
}
.userProduct .top > :not(:last-child) {
  margin-bottom: 0.3rem;
}

.userProduct .title {
  margin: 0;
}

.userProduct .bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.downloadLink {
  margin: 0.3rem 0;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 1rem;
  text-decoration: none;
  background-color: var(--brand-color);
  color: var(--color);
  transition: 0.2s;
}

.downloadLink:hover {
  opacity: 0.6;
}

.userProduct .bottom .downloadLink:not(:last-child) {
  margin-right: 0.3rem;
}

.documentationBtn {
  text-decoration: none;
}
